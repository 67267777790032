<template>
  <section class="event-league">

      <div class="card my-3">
        <div class="card-body p-0 accordion accordion-solid-header">

          <div class="d-flex align-items-center py-2 px-3 border-buttom-info" v-b-toggle="`collapse-${league[0].subcategory.id}`" >
            <h4 class="mb-0 text-light">
              <img :src="GetLeagueIcon(league[0].league)" class="img-xs" />
              {{ league[0].league }}
            </h4>
          </div>

          <b-collapse visible :id="`collapse-${league[0].subcategory.id}`">
          
            <b-container fluid v-for="event in league" :key="event.id">
              <b-row no-gutters class="card-header pl-3 pr-1">
                <b-col cols="5" sm="6">
                  {{getDisplayDate(event)}}
                </b-col>
                <b-col cols="7" sm="6"  class="games d-flex flex-row">
                  <b-container fluid>
                    <b-row no-gutters>
                      <b-col
                        :cols="12/event.games.length" v-for="game in event.games" :key="game.id"
                        :class="event.games.length">{{ game.title }}</b-col>
                    </b-row>
                  </b-container>
                  <div class="more-button">&nbsp;</div>
                </b-col>
              </b-row>

              <b-row no-gutters class="pl-2 pr-0 py-1">

                <b-col cols="5" sm="6" >
                  <div class="d-flex flex-column justify-content-between h-100">
                    <div v-for="team in event.teams" :key="team.id" class="teams d-flex h-50 align-items-center text-truncate pr-2">
                      <h5 class="mb-0" @click="goDetails(event.id)" style="cursor:pointer">
                        <img :src="GetLogoUrl(team.name)" class="img-xs rounded-circle mr-2" />
                        {{team.name}}
                      </h5>
                    </div>
                  </div>
                </b-col>

                <b-col cols="7" sm="6"  class="games d-flex flex-row">
                    <b-container fluid class="flex-grow">
                      <b-row no-gutters>
                        <b-col :cols="12/event.games.length" v-for="game in event.games" :key="game.id" :class="event.games.length" class="pt-1 pr-1">
                          <b-container fluid>
                            <b-row no-gutters v-for="option in game.options" :key="option.id">
                              <b-col>
                                <game-option :option="option" :game="game" :event="event"></game-option>
                              </b-col>
                            </b-row>
                          </b-container>
                        </b-col>
                      </b-row>
                    </b-container>
                    <div class="more-button justify-content-end">
                      <b-button class="btn-sm btn-icon-text d-flex p-0" variant="link" @click="goDetails(event.id)">
                        {{ $t('goDetails') }}<i class="mdi mdi-chevron-right btn-icon-append ml-0"></i>
                      </b-button>
                    </div>
                </b-col>
              </b-row>
            </b-container>
          </b-collapse>

        </div>
      </div>
  </section>
</template>

<script>
import gameOption from './event-league-game-option';

export default {
  components: {
    gameOption
  },
  data(){
    return {
    }
  },
  props: {
    viewType: String,
    role: String,
    league: Array
  },
  methods: {
    getDisplayDate(event){
      return this.GetFormattedDatetime(event.startDate);
    },
    
    goDetails(eventId){
      this.$router.push({path: 'eventdetail' + '/'+ eventId});
    },
  }
}
</script>