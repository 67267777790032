<template>
  <section class="dashboard">
    <div class="card d-flex justify-content-between align-items-center category mb-3 mr-0">
      <div>
        <div class="category-item">
          <i class="mdi mdi-select-all" :class="{ active: selectedCategoryId === '' }" v-b-tooltip.html.top title="All" v-on:click="filterEvent('')" />
        </div>

        <div v-for="category in categoryList" :key="category.id" class="category-item count-indicator">
          <i :class="[GetCategoryIcon(category.name), selectedCategoryId===category.id ? 'active' : '']" v-b-tooltip.html.top :title="category.name" v-on:click="filterEvent(category.id)"/>
          <span class="count-symbol bg-danger" v-if="category.eventCount > 0">{{category.eventCount}}</span>
        </div>

      </div>
      <div class="d-flex align-items-center form-group has-search my-1">
        <div>
          <span class="fa fa-search form-control-feedback"></span>
          <b-form-input v-model="searchText" type="search" class="form-control" size="sm" :placeholder="$t('search')" @input="onSearch($event)">              
          </b-form-input>
        </div>
        <div class="pl-2">
          <b-select v-model="eventSorting" :options="sortOptions" size="sm" @change="onSort"></b-select>          
        </div> 
      </div>
    </div>
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div v-if="isEmpty" class="no-item">
        <i class="fas fa-calendar-day text-info"></i>
        <h2 class="mb-2 text-warning mt-4">{{$t('noEvents')}}</h2>
      </div>


      <div v-else class="event-cards-league">
        <event-league v-for="(league, index) in leagueEvents" :league="league" :key="index" :viewType="viewType" :role="GetCurrentRole()" />
      </div>


    </div>
    <v-tour name="eventListTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
  </section>
</template>

<script>
import eventLeague from './share/event-league.vue'

export default {
  name: 'dashboard',
  components: { 
    eventLeague
  },
  data () {
    return {
      timer: null,
      viewType: '',
      rawEvents: [],
      events: [],
      leagueEvents: [],
      role: '',
      loading: true,
      isEmpty: false,
      defaultGame: [],
      betslipOptionIds: [],
      selectedCategoryId: '',
      searchText: '',
      sortOptions: [],
      eventSorting: '1',
      categoryList: [],
      tourSteps: [
        {
          target: '.event-card-option:first-of-type',  // We're using document.querySelector() under the hood
          content: this.$t('tour.step1'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.betslip-stake:first-of-type',
          content: this.$t('tour.step2'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#betslip-placebet',
          content: this.$t('tour.step3'),
          params: {
            enableScrolling: false
          }
        }
      ],
      tourOptions: {
        labels: {
            buttonSkip: this.$t('tour.skip'),
            buttonPrevious: this.$t('tour.previous'),
            buttonNext: this.$t('tour.next'),
            buttonStop: this.$t('tour.stop')
          }
      },
      tourCallbacks: {
        onSkip: this.skipTour,
        onFinish: this.skipTour
      }
    }
  },
  beforeDestroy: function(){
    clearInterval(this.timer);
    this.timer = null;
    this.$root.$off('setSortOption');
  },
  mounted: function () {
    this.getCategories();
    this.init();
    this.$root.$on('reload', () => { this.init(); });

    this.$root.$on('updateBetList', () => {
      if(this.$route.name === 'bet')
        this.getAllEvent();
    });

    this.$root.$on('setSortOption', () => {
        this.setSortOption();
    });
    
    this.$root.$on('updateEventEnablement', evt => {
      let event = this.events.find(e => e.id == evt.id);
      if(event){
        event.enableBet = evt.enableBet;
        evt.gameOptions.forEach((option)=>{
          this.updateBetStatus(event, option);
        });
      }
    });

    this.$root.$on('updateBetStatus', (option) => {
      let event = this.events.find(evt => evt.id == option.eventId);
      if(event){
        this.updateBetStatus(event, option);
      }
    });

    this.$bobwinHub.$on('eventsReceived', response => {
      this.rawEvents = JSON.parse(response.data)
      this.events = this.rawEvents;
      this.leagueEvents = this.groupBy(this.events, 'league')
      this.loading = false;
      this.isEmpty = this.events.length == 0;
      this.setDefaultGame();
      
      this.events.forEach((evt) => {
        evt.closed = false;
        let options = evt.defaultGame.options;
        evt.minOdds = options[0].odds;
        evt.maxOdds = options[options.length - 1].odds;
      });
      this.runEventStatusUpdater();
      this.updateCategoryCount();
      if (localStorage.getItem('tourSkip') !== 'true') {
        this.$tours['eventListTour'].start();
      }
    });

    this.$bobwinHub.$on('voteSaved', response => {
      if(response.result === 'success'){
        let votedOption = JSON.parse(response.data);
        if(votedOption){
          let game = this.events.filter((event) => event.id == votedOption.eventId)[0].defaultGame;
          game.options.filter((opt) => opt.id == votedOption.id)[0].voted = true;
          this.$swal({
            icon: 'success',
            title: this.$t('votedTo',{optionTitle: votedOption.title}),
            animation: false,
            confirmButtonText: this.$t('ok')
          });
          let user = JSON.parse(response.userData);
          this.$store.dispatch('setUser', user);
        }
      }
      else {
        this.ShowErrorMessage(response.message);
      }
    });

    this.$bobwinHub.$on('eventCategoriesReceived', response => {
      window.console.log(response.data);
      this.categoryList = JSON.parse(response.data);
    });
  },
  computed: {
    isVote(){
      return this.viewType === 'vote';
    }
  },
  methods: {
    init(){
      this.selectedCategoryId = '';
      this.viewType = this.$route.name.toLowerCase();
      this.loadAll();
      this.setSortOption();
    },
    getCategories(){
      this.CallHub({task: 'GetEventCategories', callback: 'eventCategoriesReceived'});
    },
    setSortOption(){
      this.sortOptions = [];
      this.sortOptions.push({ text: this.$t('latestEvent'), value: '1' });
      this.sortOptions.push({ text: this.$t('highestOdds'), value: '2' });
      this.sortOptions.push({ text: this.$t('lowestOdds'), value: '3' });
    },
    loadAll() {
      this.loading = true;
      if(this.viewType === 'vote')
        this.getAllVote();
      else
        this.getAllEvent();
    },
    getAllEvent() {
      this.CallHub({task: 'GetAllEvents', callback: 'eventsReceived'});
    },
    getAllVote() {
      this.CallHub({task: 'GetAllVotes', callback: 'eventsReceived', data: this.UserId});
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    goDetails(eventId){
      let path = this.isVote ? 'votedetail' : 'eventdetail';
      this.$router.push({path: path + '/'+ eventId});
    },
    setDefaultGame(){
      this.rawEvents.forEach((evt) => {
        if(evt.games){
          evt.defaultGame = evt.games.filter((game) => game.isDefault == true)[0];
          evt.games = this.sortedGames(evt.games);
          evt.games.forEach(game => {
            this.updateOptionButton(game);
          });
        }
      });
    },
    sortedGames(games) {
      return games.sort((a,b)=> {
        if (a.title.toLowerCase() == "money line") return -1;
        if (b.title.toLowerCase() == "money line") return 1;
        if (a.title.toLowerCase() == "1x2") return -1;
        if (b.title.toLowerCase() == "1x2") return 1;
        return a.title.localeCompare(b.title);
      });
    },
    updateOptionButton(game){
      game.options.forEach((opt) => {
        opt.isInBetslip = this.GetBetslipIndex(opt.id) > -1;
      });
    },
    updateBetStatus(event, option){
      let game = event.games.find(game => game.id == option.gameId);
        if(game){
          let gameOption = game.options.find(gameOption => gameOption.id === option.id);
          if(gameOption){
            gameOption.enableBet = option.enableBet;
            gameOption.message = option.message;
          }
        }
    },    
    getDisplayTime(event){
      if(this.viewType === 'vote'){
        let date = event.voteDeadline;
        var current = this.$moment.utc();
        return this.$moment(date).isBefore(current) ? this.$t('status.inProgress') : this.$t('voteUntil', {date: this.GetFormattedDatetime(date)});
      } else {
        return this.GetFormattedDatetime(event.startDate);
      }
    },
    runEventStatusUpdater(){
      this.timer = setInterval( () => {
        this.events.forEach((evt) => {
          if(!this.isVote) {
            if(!evt.closed && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(evt.endDate))){
              evt.closed = true;
              this.$set(this.events, evt.id, evt);
            }
            if(!evt.enableBet && this.$moment(this.$store.state.bobUTCTime).isAfter(this.$moment.utc(evt.startDate))){              
              evt.games.forEach(game => {
                game.options.forEach(option => {
                  if(option.enableBet){
                    option.enableBet = false;
                    option.updateType = 2;
                  }
                });
              });
            }

          }
        });
      }, 3000 );
    },
    filterEvent(categoryId){
      this.selectedCategoryId = categoryId;
      let filteredEvents = [];
      filteredEvents = categoryId === ''
        ? this.rawEvents
        : this.rawEvents.filter((event) => event.eventCategoryId === categoryId);
      this.events = filteredEvents.filter((event) => event.filter.indexOf(this.searchText.toLowerCase()) >= 0);
      this.leagueEvents = this.groupBy(this.events, 'league')

      if(this.eventSorting == '2'){
        this.events.sort(function (a, b) {
            return b.maxOdds - a.maxOdds
        })
      } else if(this.eventSorting == '3'){
        this.events.sort(function (a, b) {
            return a.minOdds - b.minOdds
        })
      }

      this.isEmpty = this.events.length === 0;
    },
    onSearch($event){
      this.$nextTick(()=>{
        this.searchText = $event;
        this.filterEvent(this.selectedCategoryId);
      });
    },
    onSort(){
      this.$nextTick(()=>{
        this.filterEvent(this.selectedCategoryId);
      });      
    },
    updateCategoryCount(){
      let categoryGrouped = this.GroupArrayOfObjects(this.events, "eventCategoryName");
      this.categoryList.forEach(cate=>{
        var grouped = categoryGrouped[cate.name];
        cate.eventCount = grouped ? grouped.length : 0;
      });
    },
    skipTour() {
      localStorage.setItem('tourSkip', 'true');
    }
  },
  watch:{
    $route (){
      this.init();
      clearInterval(this.timer);
      this.timer = null;
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.swal2-content {
  color: #a6a6a6;
}
.has-search {
  .form-control {
    padding-left: 2rem;
  }
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.175rem;
    height: 2.175rem;
    line-height: 1.7rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    font-size: 80%;
  }
}
</style>